import React from 'react';
import {
    Group,
    Spinner,
    CardGrid,
    Div,
    FormStatus,
} from '@vkontakte/vkui';
import EventCard from '../EventCard/EventCard';

const MyEventsList = ({events, loading, error, onEventClick}) => {
    return (
        <Group>
            {error && (
                <Div>
                    <FormStatus mode="error">
                        {error}
                    </FormStatus>
                </Div>
            )}

            {loading ? (
                <Div style={{display: 'flex', justifyContent: 'center'}}>
                    <Spinner size="large"/>
                </Div>
            ) : (
                <CardGrid size="l">
                    {events.map(event => (
                        <EventCard
                            key={event.id}
                            event={event}
                            onClick={onEventClick}
                        />
                    ))}
                </CardGrid>
            )}
        </Group>
    );
};

export default MyEventsList;
