import React, { useState, useEffect } from 'react';
import {
    Group,
    Search,
    FormLayoutGroup,
    FormItem,
    ChipsSelect,
    Spinner,
    CardGrid,
    Div,
    FormStatus,
    Input,
    Button,
} from '@vkontakte/vkui';
import EventCard from '../EventCard/EventCard';
import ApiClient from "../../api/api";

const EventsList = ({ onEventClick }) => {
    const [events, setEvents] = useState([]);
    const [offices, setOffices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchParams, setSearchParams] = useState({
        searchPhrase: '',
        byDate: null,
        byOffices: []
    });
    const api = new ApiClient();

    // Загрузка списка офисов при инициализации
    useEffect(() => {
        const fetchOffices = async () => {
            try {
                const officesData = await api.getAllOffices();
                setOffices(officesData.map(office => ({
                    value: office.id,
                    label: `${office.town} - ${office.name}`
                })));
            } catch (err) {
                console.error('Failed to load offices:', err);
                setError('Не удалось загрузить список офисов');
            }
        };
        fetchOffices();
    }, []);

    // Функция для поиска событий
    const searchEvents = async () => {
        setLoading(true);
        setError(null);
        try {
            const { searchPhrase, byDate, byOffices } = searchParams;
            const dateStr = byDate ? new Date(byDate).toISOString().split('T')[0] : null;
            const officeIds = byOffices.map(office => office.value);

            const eventsData = await api.searchEvents(searchPhrase, dateStr, officeIds);
            setEvents(eventsData);
        } catch (err) {
            console.error('Failed to search events:', err);
            setError('Не удалось загрузить события');
        } finally {
            setLoading(false);
        }
    };

    // Поиск при изменении параметров с использованием таймера
    useEffect(() => {
        const timer = setTimeout(searchEvents, 500);  // Задержка 500 мс перед поиском
        return () => clearTimeout(timer); // Очистка таймера при новом вводе
    }, [searchParams]);

    // Обработчики для изменения фильтров
    const handleSearchPhraseChange = (e) => {
        setSearchParams(prev => ({
            ...prev,
            searchPhrase: e.target.value
        }));
    };

    const handleDateChange = (e) => {
        setSearchParams(prev => ({
            ...prev,
            byDate: e.target.value
        }));
    };

    const handleOfficesChange = (values) => {
        setSearchParams(prev => ({
            ...prev,
            byOffices: values
        }));
    };

    // Функция для сброса фильтров
    const resetFilters = () => {
        setSearchParams({
            searchPhrase: '',
            byDate: null,
            byOffices: []
        });
    };

    return (
        <Group>
            <FormLayoutGroup>
                <FormItem>
                    <Search
                        value={searchParams.searchPhrase}
                        onChange={handleSearchPhraseChange}
                        placeholder="Поиск по названию"
                    />
                </FormItem>

                <FormItem top="Дата">
                    <Input
                        type="date"
                        value={searchParams.byDate || ''}
                        onChange={handleDateChange}
                    />
                </FormItem>

                <FormItem top="Офисы">
                    <ChipsSelect
                        value={searchParams.byOffices}
                        onChange={handleOfficesChange}
                        options={offices}
                        placeholder="Выберите офисы"
                        closeAfterSelect={false}
                    />
                </FormItem>

                <Div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button mode="secondary" onClick={resetFilters}>
                        Сбросить фильтры
                    </Button>
                </Div>
            </FormLayoutGroup>

            {error && (
                <Div>
                    <FormStatus mode="error">
                        {error}
                    </FormStatus>
                </Div>
            )}

            {loading ? (
                <Div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner size="large" />
                </Div>
            ) : (
                <CardGrid size="l">
                    {events.map(event => (
                        <EventCard
                            key={event.id}
                            event={event}
                            onClick={onEventClick}
                        />
                    ))}
                </CardGrid>
            )}
        </Group>
    );
};

export default EventsList;
