import React, {useState} from 'react';
import {
    Group,
    Panel,
    PanelHeader,
    FormLayoutGroup,
    FormItem,
    Input,
    Textarea,
    DatePicker,
    Button,
    ChipsSelect,
    File,
    FormStatus,
} from '@vkontakte/vkui';
import ApiClient from "../../api/api";
import sendAnalytics from "../../utils/analytics";


const CreateEvent = ({onClose, setActivePanel, setActiveEvent, panels}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [selectedOffices, setSelectedOffices] = useState([]);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const api = new ApiClient();

    // Получение списка офисов
    const [offices, setOffices] = useState([]);
    React.useEffect(() => {
        const fetchOffices = async () => {
            try {
                const officesData = await api.getAllOffices();
                setOffices(officesData.map(office => ({
                    value: office.id,
                    label: `${office.town} - ${office.name}`,
                })));
            } catch (err) {
                console.error('Ошибка загрузки офисов:', err);
                setError('Не удалось загрузить список офисов');
            }
        };
        fetchOffices();
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage('');
        console.log(startTime);
        try {
            console.log("selectedOffices:", selectedOffices)

            let selectedOfficeIds = [];
            for (let selectedOffice of selectedOffices) {
                console.log(selectedOffice, selectedOffice.value)
                selectedOfficeIds.push(selectedOffice.value)
            }

            const eventData = {
                name,
                description,
                start_date: startTime
                    ? `${startTime.getFullYear()}-${(startTime.getMonth() + 1).toString().padStart(2, '0')}-${startTime.getDate().toString().padStart(2, '0')}T${startTime.getHours().toString().padStart(2, '0')}:${startTime.getMinutes().toString().padStart(2, '0')}:${startTime.getSeconds().toString().padStart(2, '0')}`
                    : null,
                office_ids: selectedOfficeIds,
            };
            const response = await api.setupEvent(eventData);

            if (response.error) {
                setError(response.error);
            } else {
                sendAnalytics(
                    [99046428, 'reachGoal', 'create_event'],
                    {pixel_code: 'VK-RTRG-1918547-8b4Dh', event: 'create_event'},
                    {},
                    {},
                    {event: "create_event"}
                )
                setSuccessMessage('Событие успешно создано!');
                // Сброс формы после успешного создания
                setName('');
                setDescription('');
                setStartTime(new Date());
                setSelectedOffices([]);
                setActiveEvent(response);
                setActivePanel(panels.EVENT_DETAILS);
            }
        } catch (err) {
            console.error('Ошибка создания события:', err);
            setError('Не удалось создать событие');
        } finally {
            setLoading(false);
        }
    };

    const handleDateChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const updatedDateTime = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            startTime.getHours(),
            startTime.getMinutes()
        );
        setStartTime(updatedDateTime);
    };

    const handleTimeChange = (e) => {
        console.log(e.target.value);
        let [hours, minutes] = e.target.value.split(':').map(Number);
        if (minutes < 10) {
            minutes = minutes.toString().padStart(2, "0");
        }
        console.log(hours, minutes);
        const updatedDateTime = new Date(
            startTime.getFullYear(),
            startTime.getMonth(),
            startTime.getDate(),
            hours,
            minutes
        );
        console.log(updatedDateTime);
        setStartTime(updatedDateTime);
    };

    return (
        <Panel>
            <PanelHeader>Создать событие</PanelHeader>
            <Group>
                <FormLayoutGroup>
                    {error && <FormStatus mode="error">{error}</FormStatus>}
                    {successMessage && <FormStatus mode="success">{successMessage}</FormStatus>}

                    <FormItem top="Название события">
                        <Input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Введите название"
                        />
                    </FormItem>

                    <FormItem top="Описание">
                        <Textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Опишите событие"
                        />
                    </FormItem>

                    <FormItem top="Дата">
                        <Input
                            type="date"
                            onChange={(e) => handleDateChange(e)}
                        />
                    </FormItem>

                    <FormItem top="Время">
                        <Input
                            type="time"
                            onChange={(e) => handleTimeChange(e)}
                        />
                    </FormItem>

                    <FormItem top="Офисы">
                        <ChipsSelect
                            value={selectedOffices}
                            onChange={setSelectedOffices}
                            options={offices}
                            placeholder="Выберите офисы"
                        />
                    </FormItem>

                    <FormItem>
                        <Button size="l" mode="primary" stretched onClick={handleSubmit} disabled={loading}>
                            {loading ? 'Создание...' : 'Создать событие'}
                        </Button>
                    </FormItem>
                </FormLayoutGroup>
            </Group>
        </Panel>
    );
};

export default CreateEvent;
