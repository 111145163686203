import bridge from "@vkontakte/vk-bridge";

function sendAnalytics(
    metrica_data,
    retargeting_pixel_data,
    conversion_hit_data,
    track_event_data,
    custom_event_data
) {
    if (metrica_data && Object.keys(metrica_data).length !== 0) {
        window.ym(...metrica_data)
    }
    if (retargeting_pixel_data && Object.keys(retargeting_pixel_data).length !== 0) {
        bridge.send('VKWebAppRetargetingPixel', retargeting_pixel_data)
            .then((data) => {
                if (data.result) {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    if (conversion_hit_data && Object.keys(conversion_hit_data).length !== 0) {
        console.log(conversion_hit_data, conversion_hit_data !== {})
        bridge.send('VKWebAppConversionHit', conversion_hit_data)
            .then((data) => {
                if (data.result) {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    if (track_event_data && Object.keys(track_event_data).length !== 0) {
        console.log(track_event_data)
        bridge.send('VKWebAppTrackEvent', track_event_data)
            .then((data) => {
                if (data.result) {
                }
            })
            .catch((error) => {
                // Ошибка
                console.log(error);
            });
    }
    if (custom_event_data && Object.keys(custom_event_data).length !== 0) {
        bridge.send('VKWebAppSendCustomEvent', custom_event_data)
            .then((data) => {
                if (data.result) {
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }
}

export default sendAnalytics;